import React from 'react'
import { graphql } from 'gatsby'
import Post from '../components/Post'
import Page from '../components/Page'
import MainContent from '../components/MainContent'

class ArticlesRoute extends React.Component {
  render() {
    const items = []
    const posts = this.props.data.allMarkdownRemark.edges
    posts.forEach(post => {
      items.push(<Post data={post} key={post.node.fields.slug} />)
    })

    return (
      <Page {...this.props}>
        <MainContent forTiles>
          <div className="post__inner">{items}</div>
        </MainContent>
      </Page>
    )
  }
}

export default ArticlesRoute

export const pageQuery = graphql`
  query ArticlesQuery {
    ...siteQuery
    allMarkdownRemark(
      limit: 1000
      filter: { frontmatter: { layout: { eq: "post" }, draft: { ne: true } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
            categorySlug
          }
          frontmatter {
            title
            date
            category
            description
          }
        }
      }
    }
  }
`
